<template>
  <v-card style="position: relative;">
      <v-toolbar
          dark
          flat
          :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
          style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
      >
          <v-toolbar-title>Abrir relatório</v-toolbar-title>
          <v-spacer />

          <v-tooltip bottom>
              <template v-slot:activator="on">
                  <v-btn color="transparent" class="mb-2 mt-2"
                      v-on="on"
                      min-width="48px"
                      @click="$emit('closeOpenReport')"
                      depressed
                  >
                      <v-icon class="white--text">mdi-close</v-icon>
                  </v-btn>
              </template>
              <span>Fechar</span>
          </v-tooltip>
      </v-toolbar>
      <v-container>
          <v-layout wrap>
              <v-flex xs6>
                  <v-select
                      :value="selectedReport"
                      @input=" v => {selectedReport = v, $emit('getSlides', selectedReport) }"
                      :items="reports"
                      clearable
                      class="mx-2 my-4"
                      item-text="dynamic_report"
                      item-value="id_dynamic_report"                
                      label="Relatório"
                      hint="Selecione o relatório"
                      persistent-hint
                      :disabled="reports.length < 1"
                  ></v-select>
              </v-flex>
              <v-flex xs6>
                  <v-select
                      :value="selectedSlide"
                      @input=" v => selectedSlide = v"
                      :items="slides"
                      clearable
                      class="mx-2 my-4"
                      item-text="slide_title"
                      item-value="id_dynamic_report_slide"               
                      label="Slide"
                      hint="Selecione um dos slides"
                      persistent-hint
                      :disabled="!selectedReport"
                  ></v-select>
              </v-flex>
          </v-layout>
      </v-container>
      <v-divider class="mt-1 mb-1"></v-divider>
      <div class="light" style="position: sticky; bottom: 0; width: 100%;">
          <v-card-actions class="px-6 py-6">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="$emit('closeOpenReport')">
                  Cancelar
              </v-btn>
              <v-btn color="primary" @click="$emit('reportSelected', selectedSlide)">
                  Abrir
              </v-btn>
          </v-card-actions>
      </div>
  </v-card>
</template>
<script>
export default {
  props: {
    reports: {
      type: Array,
      required: true
    },
    slides: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      selectedReport: null,
      selectedSlide: null
    }
  }
}
</script>
