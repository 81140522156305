<template>
  <div>
     <v-row no-gutters class="d-flex justify-start align-center mb-3" style="height: 24px !important; width: 100%;">
        <v-col class="d-flex justify-start" style="height: 24px !important;">
            <v-tabs  
                v-model="tab"
                height="24px"
            >
                <v-tabs-slider color="primary"></v-tabs-slider>

                <v-tab :disabled="select.length < 1">
                    <span class="text-caption text-capitalize"> Evento</span>
                </v-tab>
                <v-tab :disabled="select.length < 1">
                    <span class="text-caption text-capitalize"> Filtros</span>
                </v-tab>
                <v-tab :disabled="select.length < 1">
                    <span class="text-caption text-capitalize"> Exibir</span>
                </v-tab>
            </v-tabs>
        </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
        <v-tab-item>
            <v-layout class="mx-4">
                <v-flex xs6>
                    <v-select
                        :value="select"
                        @input="v => $emit('changeEvent', v)"
                        :items="eventItems"
                        height="42.5"
                        class="mx-2 mt-0 mb-4"
                        item-text="description"
                        item-value="id"                
                        label="Eventos"
                        multiple
                        hint="Selecione os Eventos para comparar"
                        :disabled="filters.length < 1"
                        :loading="filters.length < 1"
                        persistent-hint
                    ></v-select>
                </v-flex>
                <v-flex xs6>
                    <v-row class="pa-0 my-0 ml-4">
                        <v-radio-group :value="option" @change="v => $emit('changeOption', v)" row class="ml-4 mt-4">
                          <v-radio
                              label="Ano"
                              value="YEAR"
                          ></v-radio>
                          <v-radio
                              label="Mês"
                              value="YEAR_MONTH"
                          ></v-radio>
                        </v-radio-group>
                        <v-divider v-if="showSelectYear && select.length > 0" vertical class="mx-4"></v-divider>
                        <v-radio-group v-if="showSelectYear && select.length > 0" :value="selectedYear" @change="v => $emit('changeSelectedYear', v)" row class="ml-4 mt-4">
                            <v-radio
                                :label="items.find(item => item.id === select[0]).fiscal_year.toString()"
                                value="1"
                            ></v-radio>
                            <v-radio
                                :label="parseInt(items.find(item => item.id === select[0]).fiscal_year + 1).toString()"
                                value="2"
                            ></v-radio>
                        </v-radio-group>
                    </v-row>
                </v-flex>
            </v-layout>
        </v-tab-item>
        <v-tab-item>
            <v-layout wrap mx-4 mt-2>
                <v-flex v-for="(column, index) in filters" :key="column.column_id" xs2>
                    <v-autocomplete
                        :value="column.selectedFilters"
                        @input="v => { $emit('changeFilter', { value: v, index: index}), searchFilters = {}}"
                        :items="column.items"
                        :hide-no-data="true"
                        clearable
                        class="mx-2 my-n2"
                        item-text="text"
                        item-value="id"                
                        :label="column.text"
                        @click="showFilterItems(column)"
                        :loading="filterIsLoading(column)"
                        :search-input.sync="searchFilters[column.name]"
                        multiple
                    />
                </v-flex>
            </v-layout>
        </v-tab-item>
        <v-tab-item>
            <v-container fluid fill-height>
                <v-layout wrap>
                    <v-flex v-for="column in selects" :key="column.index" xs2>
                        <v-checkbox
                            v-model="column.visible"
                            @click="$emit('changeSelect', column)"
                            :label="column.text"
                            class="mx-2 my-n2"
                        />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  props: {
    select: {
      type: Array,
      required: true
    },
    selects: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    eventItems: {
      type: Array,
      required: true
    },
    filters: {
      type: Array,
      required: true
    },
    selectedYear: {
      type: String,
      required: true
    },
    showSelectYear: {
      type: Boolean,
      required: true
    },
    option: {
      type: String,
      required: true
    },
    loadingFilters: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      tab: 0,
      searchFilters: {},
    }
  },

  methods: {
    async showFilterItems(filter) {
        if (filter?.items?.length < 1) {
            this.loadingFilters.push(filter.name)

            try {
                let isCategoryParent = filter.columnId === 'id_product_category_parent' ? true : false
                let payload = { isCategoryParent, filter: { conditions: filter.conditions } }
                if (filter.columnId === 'id_cost_center' && this.selectedReport || filter.columnId === 'id_product' && this.selectedReport ) {
                    payload.id_dynamic_report = this.selectedReport
                }
                let res = await this.$http.post(filter.server + '/list-options', payload)
                // let res = await this.$http.post(filter.local + '/list-options', {})
                if (res) {
                    let foundFilter = this.filters.find(f => f.columnId === filter.columnId)
                    foundFilter.items = res.data.rows

                    this.loadingFilters.pop()
                }
            } catch (err) {
                this.loadingFilters.pop()
                this.$fnError(err)
            }
        }
    },

    filterIsLoading(filter) {
        if (this.loadingFilters.includes(filter.name)) {
            return true
        } else {
            return false
        }
    },
  }
  
}
</script>
