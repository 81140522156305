<template>
  <v-card style="position: relative;">
      <v-toolbar
          dark
          flat
          :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
          style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
      >
          <v-toolbar-title>Copiar slide</v-toolbar-title>
          <v-spacer />

          <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                  <v-btn color="transparent" class="mb-2 mt-2"
                      v-on="on"
                      min-width="48px"
                      @click="$emit('closeCopyReport')"
                      depressed
                  >
                      <v-icon class="white--text">mdi-close</v-icon>
                  </v-btn>
              </template>
              <span>Fechar</span>
          </v-tooltip>
      </v-toolbar>
      <v-container>
          <v-layout wrap>
              <v-flex xs6>
                  <v-select
                      v-model="selectedReport"
                      :items="reports"
                      clearable
                      class="mx-2 my-4"
                      item-text="dynamic_report"
                      item-value="id_dynamic_report"             
                      label="Relatório"
                      hint="Selecione para qual relatória irá o slide"
                      persistent-hint
                  ></v-select>
              </v-flex>
          </v-layout>
      </v-container>
      <v-divider class="mt-1 mb-1"></v-divider>
      <div class="light" style="position: sticky; bottom: 0; width: 100%;">
          <v-card-actions class="px-6 py-6">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="$emit('closeCopyReport')">
                  Cancelar
              </v-btn>
              <v-btn color="primary" @click="copySlide(selectedReport)">
                  Copiar
              </v-btn>
          </v-card-actions>
      </div>
  </v-card>
</template>
<script>
export default {
  props: {
    reports: {
        type: Array,
        required: true
    },
    selectedSlide: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      selectedReport: null
    }
  },

  computed: {
      endpointDynamicReport() {
          return [this.$ipDynamicReport, "dynamic-report"]
      },
  },
  
  methods: {
    async copySlide (report) {
        try {
            const payload = {
                id_dynamic_report_slide: this.selectedSlide,
                id_dynamic_report: report
            }

            const res = await this.$http.post(
                this.endpointDynamicReport[0] + 'dynamic-report-slide' + "/copy-to-report",
                payload
            )
            if (res) {
                this.$toast.success(res.data.msg)
                this.$emit('reportSelected')
            }
        } catch (err) {
            this.$fnError(err)
        }
    },
  } 
}
</script>
