<template>
  <div>
    <p v-if="data.length > 1 && !loadingEvents" style="position: absolute;">
        <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
                <span v-on="on" class="ml-2">
                    <v-icon class="primary--text">
                        mdi-chart-tree
                    </v-icon>
                    Visualização
                </span>
            </template>
            <span class="caption">
                Escolha o formato de exibição do relatório
            </span>
        </v-tooltip>
        <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
                <span v-on="on" style="margin-left: 190px;">
                    <v-icon class="primary--text">
                        mdi-import
                    </v-icon>
                    Campos gerenciais
                </span>
            </template>
            <span class="caption">
                Arraste os campos para adicionar ao relatório
            </span>
        </v-tooltip>
    </p>
    <p v-if="data.length > 1 && !loadingEvents" style="margin-top: 175px; position: absolute;">
        <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
                <span v-on="on" class="ml-2">
                    <v-icon class="primary--text">
                        mdi-sigma
                    </v-icon>
                    Valores
                </span>
            </template>
            <span class="caption">
                Escolha o formato de exibição dos valores no relatório
            </span>
        </v-tooltip>
        <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
                <span v-on="on" style="margin-left: 220px;">
                    <v-icon class="primary--text">
                        mdi-table-column
                    </v-icon>
                    Colunas
                </span>
            </template>
            <span class="caption">
                Coloque os campos a serem visualizados em colunas no relatório
            </span>
        </v-tooltip>
        <br>
        <br>
        <span class="grey--text text--darken-2 caption ml-8">
            Ordenar linhas e colunas:
        </span>
    </p>
    <p v-if="data.length > 1 && !loadingEvents" :style="labelClass[0]">
        <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
                <span v-on="on" class="ml-2">
                    <v-icon class="primary--text">
                        mdi-table-row
                    </v-icon>
                    Linhas
                </span>
            </template>
            <span class="caption">
                Coloque os campos a serem visualizados em linhas no relatório
            </span>
        </v-tooltip>
        <v-layout class="mt-n6" style="margin-left:300px;">
            <v-flex>
                <span class="ml-5">
                    <v-icon class="primary--text">
                        mdi-table-large
                    </v-icon>
                    Relatório
                </span>
            </v-flex>
            <v-flex>
                <v-checkbox
                    v-model="showTotalRow"
                    label="Mostrar total das linhas"
                    class="mt-n1 ml-4"
                />
            </v-flex>
            <v-flex>
                <v-checkbox
                    v-model="showTotalCol"
                    label="Mostrar total das colunas"
                    class="mt-n1 ml-4"
                />
            </v-flex>
            <v-flex>
                <v-btn class="mr-2 ml-4 mt-n1" dark color="primary" @click="$emit('openPreview')" :disabled="select.length < 1">
                    <v-icon left class="mr-2">mdi-magnify</v-icon>
                    Preview
                </v-btn>
            </v-flex>
        </v-layout>
    </p>


    <v-icon v-if="data.length > 1 && !loadingEvents" style="margin-left: 225px; margin-top: 55px; position: absolute;">
        mdi-menu-down
    </v-icon>
    <v-icon v-if="data.length > 1 && !loadingEvents" style="margin-left: 225px; margin-top: 270px; position: absolute;">
        mdi-menu-down
    </v-icon>
    <v-icon v-if="data.length > 1 && !loadingEvents && labelClass[1]" style="margin-left: 225px; margin-top: 315px; position: absolute;">
        mdi-menu-down
    </v-icon>
    <v-icon v-if="data.length > 1 && !loadingEvents && labelClass[2]" style="margin-left: 225px; margin-top: 355px; position: absolute;">
        mdi-menu-down
    </v-icon>

    <vue-pivottable-ui
        v-if="!loadingEvents"
        v-model="config"
        :data="data"
        :rowTotal="showTotalRow"
        :colTotal="showTotalCol"
        :localeStrings="localeStrings[locale]"
        :rendererName="rendererName"
        :aggregatorName="aggregatorName"
        :tableColorScaleGenerator="colorScaleGenerator"
        :attributes="attributes"
        :sorters="sorters"
        :rows="rows"
        :cols="cols"
        :vals="vals"
        :disabledFromDragDrop="disabledFromDragDrop"
        :sortonlyFromDragDrop="sortonlyFromDragDrop"
        :hiddenFromDragDrop="hiddenFromDragDrop"
        :hiddenFromAggregators="hiddenFromAggregators"
        rowOrder="key_a_to_z"
        class="mt-n4"
    >
        <colgroup slot="colGroup">
            <col :width="300" />
            <col />
        </colgroup>
        <div v-if="loading" slot="output">loading...</div>
        <template
            v-if="!loading"
            slot="output"
            slot-scope="{ pivotData }"
        >
            {{ pivotData }}
        </template>
        <template slot="pvtAttr" slot-scope="{ name }">
            {{ name }}
        </template>
    </vue-pivottable-ui>
  </div>
</template>
<script>
import { VuePivottableUi, PivotUtilities } from "../../../lib/vue-pivottable/src"
import { scaleLinear } from "d3-scale"

export default {
    name: "ZnapPivotTable",

    components: { VuePivottableUi },

    props: {
      data: {
        type: Array,
        required: true
      },
      loadingEvents: {
        type: Boolean,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      },
      accountGroups: {
        type: Array,
        required: true
      },
      attributes: {
        type: Array,
        required: true
      },
      cols: {
          type: Array,
          required: true
      },
      rows: {
          type: Array,
          required: true
      },
      rendererName: {
          type: String,
          required: true
      },
      aggregatorName: {
          type: String,
          required: true
      },
      vals: {
          type: Array,
          required: true
      },
      select: {
          type: Array,
          required: true
      },
      showTotalCol : {
        type: Boolean,
        required: true
      },
      showTotalRow : {
        type: Boolean,
        required: true
      },
      disabledFromDragDrop: {
        type: Array,
        required: true
      },
      sortonlyFromDragDrop: {
        type: Array,
        required: true
      },
      hiddenFromDragDrop: {
        type: Array,
        required: true
      },
      hiddenFromAggregators: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        config: {},
        labelClass: [
            'position: absolute; margin-top: 390px;',
            true,
            false
        ],
        yearMonths: [
            'Jan/20', 'Fev/20', 'Mar/20', 'Abr/20', 'Mai/20', 'Jun/20', 'Jul/20', 'Ago/20', 'Set/20', 'Out/20', 'Nov/20', 'Dev/20',
            'Jan/21', 'Fev/21', 'Mar/21', 'Abr/21', 'Mai/21', 'Jun/21', 'Jul/21', 'Ago/21', 'Set/21', 'Out/21', 'Nov/21', 'Dev/21',
            'Jan/22', 'Fev/22', 'Mar/22', 'Abr/22', 'Mai/22', 'Jun/22', 'Jul/22', 'Ago/22', 'Set/22', 'Out/22', 'Nov/22', 'Dev/22',
            'Jan/23', 'Fev/23', 'Mar/23', 'Abr/23', 'Mai/23', 'Jun/23', 'Jul/23', 'Ago/23', 'Set/23', 'Out/23', 'Nov/23', 'Dev/23'
        ],
        periodMonths: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        localeStrings: {
            en: {
                renderError:
                    "An error occurred rendering the PivotTable results.",
                computeError:
                    "An error occurred computing the PivotTable results.",
                uiRenderError: "An error occurred rendering the PivotTable UI.",
                selectAll: "Select All",
                selectNone: "Select None",
                tooMany: "too many values to show",
                filterResults: "Filter values",
                totals: "Totals",
                only: "only",
                rendererNames: {
                    Table: "Table",
                    "Table Heatmap": "Table Heatmap",
                    "Table Col Heatmap": "Table Col Heatmap",
                    "Table Row Heatmap": "Table Row Heatmap",
                    "Export Table TSV": "Export Table TSV",
                    "Grouped Column Chart": "Grouped Column Chart",
                    "Stacked Column Chart": "Stacked Column Chart",
                    "Grouped Bar Chart": "Grouped Bar Chart",
                    "Stacked Bar Chart": "Stacked Bar Chart",
                    "Line Chart": "Line Chart",
                    "Dot Chart": "Dot Chart",
                    "Area Chart": "Area Chart",
                    "Scatter Chart": "Scatter Chart",
                    "Multiple Pie Chart": "Multiple Pie Chart",
                },
                aggregatorMap: {
                    Count: "Count",
                    "Count Unique Values": "Count Unique Values",
                    "List Unique Values": "List Unique Values",
                    Sum: "Sum",
                    "Integer Sum": "Integer Sum",
                    Average: "Average",
                    Median: "Median",
                    "Sample Variance": "Sample Variance",
                    "Sample Standard Deviation": "Sample Standard Deviation",
                    Minimum: "Minimum",
                    Maximum: "Maximum",
                    First: "First",
                    Last: "Last",
                    "Sum over Sum": "Sum over Sum",
                    "Sum as Fraction of Total": "Sum as Fraction of Total",
                    "Sum as Fraction of Rows": "Sum as Fraction of Rows",
                    "Sum as Fraction of Columns": "Sum as Fraction of Columns",
                    "Count as Fraction of Total": "Count as Fraction of Total",
                    "Count as Fraction of Rows": "Count as Fraction of Rows",
                    "Count as Fraction of Columns":
                        "Count as Fraction of Columns",
                },
            },
            pt: {
                renderError:
                    "Ocorreu um erro ao renderizar os resultados da tabela dinâmica.",
                computeError:
                    "Ocorreu um erro ao calcular os resultados da tabela dinâmica.",
                uiRenderError: "Ocorreu um erro ao renderizar a Interface de Uso da tabela dinâmica.",
                selectAll: "Selecionar tudo",
                selectNone: "Selecione Nenhum",
                tooMany: "muitos valores para mostrar",
                filterResults: "Filtrar valores",
                totals: "Totais",
                only: "apenas",
                rendererMap: {
                    Table: 'Tabela',
                    'Table Heatmap': 'Mapa de calor da tabela',
                    'Table Col Heatmap': 'Mapa de calor de coluna da tabela',
                    'Table Row Heatmap': 'Mapa de calor de linha da tabela',
                    'Export Table TSV': 'Copiar para Excel',
                    'Grouped Column Chart': 'Gráfico de colunas agrupadas',
                    'Stacked Column Chart': 'Gráfico de colunas empilhadas',
                    'Grouped Bar Chart': 'Gráfico de Barras Agrupadas',
                    'Stacked Bar Chart': 'Gráfico de Barras Empilhadas',
                    'Line Chart': 'Gráfico de linhas',
                    'Dot Chart': 'Gráfico de pontos',
                    'Area Chart': 'Gráfico de área',
                    'Scatter Chart': 'Gráfico de dispersão',
                    'Multiple Pie Chart': 'Gráfico de pizza múltipla',
                },
                aggregatorMap: {
                    Count: "Contagem",
                    "Count Unique Values": "Contar valores exclusivos",
                    "List Unique Values": "Listar valores únicos",
                    Sum: "Soma",
                    "Integer Sum": "Soma Inteira",
                    Average: "Média",
                    Median: "Mediana",
                    "Sample Variance": "Variância da amostra",
                    "Sample Standard Deviation": "Desvio padrão da amostra",
                    Minimum: "Mínimo",
                    Maximum: "Máximo",
                    First: "Primeiro",
                    Last: "Último",
                    "Sum over Sum": "Soma sobre Soma",
                    "Sum as Fraction of Total": "Soma como fração do total",
                    "Sum as Fraction of Rows": "Soma como fração de linhas",
                    "Sum as Fraction of Columns": "Soma como fração de colunas",
                    "Count as Fraction of Total": "Contar como fração do total",
                    "Count as Fraction of Rows": "Contar como fração de linhas",
                    "Count as Fraction of Columns":
                        "Contar como fração de colunas",
                }
            },
                ko: {
                renderError: '피벗 테이블 결과를 렌더링하는 동안 오류가 발생 했습니다.',
                computeError: '피벗 테이블 결과를 계산하는 동안 오류가 발생 했습니다.',
                uiRenderError: '피벗 테이블 UI를 렌더링하는 동안 오류가 발생 했습니다.',
                selectAll: '모두 선택',
                selectNone: '선택 안함',
                tooMany: '표시 할 값이 너무 많습니다.',
                filterResults: '값 필터링',
                totals: '합계',
                only: '단독',
                rendererMap: {
                    Table: '테이블',
                    'Table Heatmap': '테이블 히트맵',
                    'Table Col Heatmap': '테이블 열 히트맵',
                    'Table Row Heatmap': '테이블 행 히트맵',
                    'Export Table TSV': '테이블 TSV로 내보내기',
                    'Grouped Column Chart': '그룹화된 차트',
                    'Stacked Column Chart': '누적 차트',
                    'Grouped Bar Chart': '그룹화된 막대형 차트',
                    'Stacked Bar Chart': '누적 막대형 차트',
                    'Line Chart': '라인 차트',
                    'Dot Chart': '도트 차트',
                    'Area Chart': '영역 차트',
                    'Scatter Chart': '분산형 차트',
                    'Multiple Pie Chart': '다중 원형 차트'
                },
                aggregatorMap: {
                    Count: '개수',
                    'Count Unique Values': '고유 값 개수',
                    'List Unique Values': '고유 값 목록',
                    Sum: '합계',
                    'Integer Sum': '정수 합계',
                    Average: '평균',
                    Median: '중앙',
                    'Sample Variance': '표본 분산',
                    'Sample Standard Deviation': '샘플 표준 편차',
                    Minimum: '최소',
                    Maximum: '최대',
                    First: '첫 번째',
                    Last: '마지막',
                    'Sum over Sum': '누적 합계',
                    'Sum as Fraction of Total': '부분별 비율 합계',
                    'Sum as Fraction of Rows': '행별 비율 합계',
                    'Sum as Fraction of Columns': '열별 비율 합계',
                    'Count as Fraction of Total': '전체 중 부분 개수',
                    'Count as Fraction of Rows': '행 부분 개수',
                    'Count as Fraction of Columns': '열 부분 개수'
                }
            }
        },
        //getsystem.language
        // Count, Count as Fraction of * : 1 select, height: 126
        // Sum over Sum : 3 selects, height: 212
        // Demais: 2 selects, height: 169
        locale: "pt",
      }
    },

    computed: {
        sorters () {
            // const sortAs = PivotUtilities.sortAs(this.accountGroups)
            let orderedArray = [...this.accountGroups]
            return {
                "Conta gerencial": PivotUtilities.sortAs([...orderedArray]),
                "Ano/mês": PivotUtilities.sortAs([...this.yearMonths]),
                "Período (mês)": PivotUtilities.sortAs([...this.periodMonths])
            }
        }
    },

    created () {
        this.$root.$on('changeVal', ($event) => {
            if (
                $event === 'Count' ||
                $event === 'Count as Fraction of Total'||
                $event === 'Count as Fraction of Rows' ||
                $event === 'Count as Fraction of Columns'
            ) {
                this.labelClass = [
                    'position: absolute; margin-top: 340px;',
                    false,
                    false
                ]
            }
            else if ($event === 'Sum over Sum') {
                this.labelClass = [
                    'position: absolute; margin-top: 425px;',
                    true,
                    true
                ]
            }
            else {
                this.labelClass = [
                    'position: absolute; margin-top: 385px;',
                    true,
                    false
                ]
            }
        })
    },

    methods: {
        colorScaleGenerator(values) {
            const scale = scaleLinear()
                .domain([0, Math.max.apply(null, values)])
                .range(["#fff", "#88f"])
            return (x) => {
                return { "background-color": scale(x) }
            }
        },
    }
}
</script>